/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    blockquote: "blockquote",
    ul: "ul",
    li: "li",
    a: "a",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 28번째 로그에서는 다크룸, 애플 분기 실적 발표, 인터넷전문은행 사용성 등에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Node.js 12 버전 릴리스 소식을 전했는데, 현재는 LTS 버전이 아닙니다. 12 버전은 2019년 4월 23일 Current로 릴리스되었으며 2019년 10월 22일 Active LTS로 릴리스될 예정입니다."), "\n"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://audioclip.naver.com/channels/1807/"
  }, "stdout.fm : 오디오클립")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.patreon.com/stdoutfm"
  }, "stdout.fm 정기 후원 - Patreon")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/news--ubuntu-14-04-end-of-life"
  }, "우분투(Ubuntu) 14.04 트러스티 타르 LTS 4월 30일 부로 지원 종료 | 44bits.io")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/news--cloud-run-is-launched-on-gcp"
  }, "구글, 도커(Docker) 컨테이너 기반 서버리스 서비스인 클라우드 런(Cloud Run) 발표 | 44bits.io")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/news--aws-introduces-advanced-parameter-store"
  }, "AWS 시스템 매니저 파라미터 스토어 개선 발표: 고급 파라미터 추가 및 API 처리량 증가 | 44bits.io")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/news--docker-found-unauthorized-access-to-docker-hub-database"
  }, "4월 25일, 도커 허브(Docker Hub) 계정 정보 유출: 비밀번호 변경 등 조치 필요 | 44bits.io")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/news--aws-rds-postgresql-supports-data-import-from-s3"
  }, "AWS RDS for PostgreSQL에서 S3 데이터 임포트 기능 지원 | 44bits.io")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/news--aws-announced-new-hongkong-region"
  }, "아마존 웹 서비스(AWS), 아시아 태평양 지역에 홍콩 리전(ap-east-1)을 새롭게 추가 | 44bits.io")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/news--fluentd-has-graduated-cncf"
  }, "CNCF, Fluentd 프로젝트 졸업을 발표 | 44bits.io")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.ubuntu.com/about/release-cycle"
  }, "Ubuntu release cycle | Ubuntu")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://buy.ubuntu.com/"
  }, "Ubuntu Advantage")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://wiki.ubuntu.com/Releases"
  }, "Releases - Ubuntu Wiki")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.eurogamer.net/articles/2019-04-29-nintendo-pulls-switch-indie-game-from-eshop-after-dev-reveals-he-sneaked-in-basic-code-editor-easter-egg"
  }, "Nintendo pulls Switch eShop game after dev reveals he sneaked in basic code editor \"Easter egg\" • Eurogamer.net")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ruby.social/@amirrajan/101985836120728909"
  }, "Amir Rajan: \"Dear Ruby devs and game devs. I have a crazy anno…\" - Ruby.social")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/tootsuite/mastodon"
  }, "tootsuite/mastodon: Your self-hosted, globally interconnected microblogging community")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://rubykaigi.org/2019/presentations/amirrajan.html"
  }, "Building a game for the Nintendo Switch using Ruby - RubyKaigi 2019")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/2011_PlayStation_Network_outage"
  }, "2011 PlayStation Network outage - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EB%86%8D%ED%98%91_%EC%A0%84%EC%82%B0%EB%A7%9D_%EB%A7%88%EB%B9%84_%EC%82%AC%ED%83%9C"
  }, "농협 전산망 마비 사태 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ruby.social/about"
  }, "ruby.social - Ruby.social")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/kr/newsroom/2019/04/apple-reports-second-quarter-results/"
  }, "Apple, 2분기 실적 발표 - Apple (KR)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/newsroom/pdfs/Q2%20FY19%20Consolidated%20Financial%20Statements.pdf"
  }, "Q2 FY19 Consolidated Financial Statements.pdf")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://9to5mac.com/2018/08/02/apple-becomes-first-company-to-hit-1-trillion-market-cap-according-to-apples-own-stocks-app/"
  }, "AAPL hits $1 trillion market cap, Apple first publicly traded company to reach 13 digit valuation - 9to5Mac")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://9to5mac.com/2019/05/01/apple-hits-1-trillion-market-cap-once-again-following-better-than-expected-earnings-results/"
  }, "Apple hits $1 trillion market cap once again following better-than-expected earnings results, stock up 6% - 9to5Mac")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/kr/apple-watch-series-4/"
  }, "Apple Watch Series 4 - Apple ")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/apple-watch-hermes/"
  }, "Apple Watch Hermès - Apple")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/kr/music/"
  }, "Music - Apple (KR)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.gizchina.com/2019/04/30/counterpoint-iphone-x-was-the-best-selling-device-of-2018/"
  }, "Counterpoint: iPhone X was the best-selling device of 2018 - Gizchina.com")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Mac_Pro"
  }, "Mac Pro - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://onedrive.live.com/about/ko-kr/"
  }, "Microsoft OneDrive")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.nytimes.com/2019/04/30/technology/apple-stock-buyback-quarterly-results.html"
  }, "Apple’s Plan to Buy $75 Billion of Its Stock Fuels Spending Debate - The New York Times")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.mk.co.kr/news/economy/view/2019/04/246721/"
  }, "당국 \"토스 금융자본이라 단정못해\"…인터넷은행 인가 ", React.createElement(_components.code, null, "변수"), " - 매일경제")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.kbanknow.com/ib20/mnu/PBKMAN000000"
  }, "케이뱅크")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.kakaobank.com/"
  }, "카카오뱅크")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.v.daum.net/v/20190319102602476"
  }, "사용자 경험(UX)의 힘, 카카오뱅크 모바일앱 | Daum 뉴스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.kakaobank.com/177"
  }, "카카오뱅크 블로그 :: 카카오뱅크는 왜 공인인증서를 사용하지 않을까?")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hankyung.com/economy/article/201807166397i"
  }, "카카오뱅크 26주 적금 출시 20일만에 30만좌 돌파 | 한경닷컴")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.toss.im/2019/04/04/newsroom/press-release/toss-card/"
  }, "토스(toss), 온·오프라인 결제 지원하는 ‘토스카드’ 출시")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://banksalad.com/"
  }, "신경꺼도 내 돈 관리 ㅣ 뱅크샐러드")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://news.mt.co.kr/mtview.php?no=2018051815153656252"
  }, "전세대출, 신용카드 발급 거부·이용한도 축소 논란 - 머니투데이 뉴스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://whooing.com/"
  }, "후잉가계부(whooing)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.mint.com/"
  }, "Budget Tracker & Planner | Free Online Money Management | Mint")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EB%B0%A9%EC%B9%B4%EC%8A%88%EB%9E%91%EC%8A%A4"
  }, "방카슈랑스 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.mk.co.kr/news/economy/view/2019/04/204598/"
  }, "카카오, 카뱅 대주주 적격 심사 신청 - 매일경제")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://byline.network/2019/04/17-46/"
  }, "클라우드에 올인, 대한항공 \"전 직원이 AWS 배운다\" - Byline Network")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
